(<template>
  <div class="accept-invite">
    <tt-progress />
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        userUid: 'UserInfoStore/userUid'
      })
    },
    beforeMount() {
      if (this.$route.params.token && this.$route.query.strategy) {
        const token = this.$route.params.token;
        const strategy = this.$route.query.strategy;
        const form = new FormData();
        form.append('token', token);
        form.append('strategy', strategy);
        this.$store.dispatch('acceptInvite', form).then((data) => {
          if (data.uid && data.token) {
            this.$store.dispatch('TTAuthStore/logOut').then(() => {
              this.$store.dispatch('TTAuthStore/logIn', {token: data.token, uid: this.userUid});
              this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$route.query.uid).then(() => {
                this.$router.replace(this.$makeRoute({name: 'SignIn'}));
              });
            });
          } else if (data.token) {
            if (this.$store.state.TTAuthStore.loggedIn) {
              this.$store.dispatch('TTAuthStore/logOut').then(() => {
                this.$router.replace(this.$makeRoute({name: 'NewPassword', query: {token: data.token, wizard: true}}));
              });
            } else {
              this.$router.replace(this.$makeRoute({name: 'NewPassword', query: {token: data.token, wizard: true}}));
            }
          } else {
            this.$store.dispatch('TTAuthStore/logOut').then(() => {
              this.$router.replace(this.$makeRoute({name: 'SignIn'}));
            });
          }
        }).catch(() => {
          this.$router.replace(this.$makeRoute({name: 'SignIn'}));
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('This invitation has expired.<br>Could you please ask your enterprise administrator to re-send an invitation to you?')
          });
        });
      } else {
        this.$router.replace(this.$makeRoute({name: 'SignIn'}));
      }
    }
  };
</script>

<style scoped>
  .accept-invite {
    display: block;
    width: 100%;
    height: 100%;
  }
</style>
